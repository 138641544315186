import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <>
      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-5">
          <h1 class="display-5 fw-bold">Awesome!👏</h1>
          <p class="col-md-8 fs-4">
            You have been Successfully signed up to the newsletter, look forward
            to lots of awesome content!
          </p>
          <Link to="/" class="btn btn-outline-dark">
            Go Home
          </Link>
        </div>
      </div>

      <div className="fixed-bottom">
        <Footer />
      </div>
    </>
  );
};

export default Success;
