import React from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  return (
    <div className="container">
      <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-dark mb-3 adminNavBody">
        <div className="flex-row d-flex adminNavLeft">
          <Link to="/dashboard" className="navbar-brand ml-4">
            <img
              src="/Images/LSlogo.png"
              alt="Logicspice logo"
              width="30"
              className="mr-2 mb-1"
            />
            Admin Panel
          </Link>
        </div>
        <div className="navbar-collapse collapse adminNavRight" id="collapsingNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className="nav-link waves-effect waves-light text-white"
                href="/login"
                onClick={() => alert("Do you want to Log Out?")}
              >
                <i className="fa-solid fa-user mr-4"></i> Logout{" "}
                <i className="fa-solid fa-right-from-bracket"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default Navbar;
