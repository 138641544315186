import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import avatar from "../assets/image.png";
import JoditEditor from "jodit-react";
import HTMLReactParser from "html-react-parser";

const PostBlog = () => {
  const navigate = useNavigate();
  const editor = useRef(null);


  const [post, setPost] = useState({
    id: "",
    title: "",
    imageurl: "",
    description: "",
    category: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      post.title === "" &&
      post.imageurl === "" &&
      post.description === "" &&
      post.category === ""
    ) {
      alert("⚠ Please fill the details properly");
    } else {
      Axios.post("https://lsblogger-backend.logicspice.com/postblog", post);
      alert("✔ Blog submitted successfully");
      navigate("/viewblogs");
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setPost({ ...post, imageurl: base64 });
  };
  console.log(post);

  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />
            <div className="col-md-9 col-lg-10">
              <section className="container sub" style={{marginTop:"80px"}}>
                <h4 className="mb-5 mt-5 text-center">
                  <strong>Enter details of Post</strong>
                </h4>

                <div className="row d-flex justify-content-center">
                  <div className="col-md-6">
                    <form>
                      <div className="row mb-4">
                        <div className="col">
                          <div className="form-outline">
                            <label htmlFor="">
                              <strong>Title:</strong>
                            </label>
                            <input
                              type="text"
                              id="form3Example2"
                              className="form-control"
                              name="title"
                              placeholder="Blog Title"
                              value={post.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-outline mb-4">
                        <label
                          htmlFor="file-upload"
                          className="custom-file-upload"
                        >
                          <strong>Upload Image:</strong>
                        </label>
                        <p style={{ color: "red" }}>
                          *Only JPEG, JPG and PNG files less than 1 MB supported
                        </p>
                        <input
                          className="ml-2"
                          type="file"
                          lable="Image"
                          name="imageurl"
                          id="file-upload"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => handleFileUpload(e)}
                        />
                        <img
                          src={post.imageurl || avatar}
                          alt=""
                          style={{ width: "100px" }}
                        />
                        {post.imageurl ? (
                          <button
                            className="btn-sm btn-outline-dark ml-3"
                            onClick={() => setPost({ ...post, imageurl: "" })}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-outline mb-4">
                        <label htmlFor="">
                          <strong>Description:</strong>
                        </label>
                        <JoditEditor
                          ref={editor}
                          name="description"
                          value={post.description}
                          onChange={(description) =>
                            handleChange({
                              target: {
                                value: description,
                                name: "description",
                              },
                            })
                          }
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label for="cars">
                          <strong>Choose a category:</strong>
                        </label>

                        <select
                          name="category"
                          id="form3Example4"
                          className="form-control"
                          value={post.category}
                          onChange={handleChange}
                        >
                          <option value="Select" inactive>
                            Select
                          </option>
                          <option value="Technology">Technology</option>
                          <option value="Tourism">Tourism</option>
                          <option value="Art">Art</option>
                          <option value="Science">Science</option>
                          <option value="Sports">Sports</option>
                        </select>
                      </div>
                      <ReCAPTCHA
                        sitekey="6LeRpjklAAAAAHyjfMmz8anE4GMxRuZf_vuEVYgZ"
                        required
                      />
                      <button
                        type="submit"
                        className="btn btn-outline-dark mb-4 mt-2"
                        onClick={handleSubmit}
                      >
                        Submit Blog
                      </button>
                    </form>
                    {HTMLReactParser(post.description)}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default PostBlog;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
