import React, { useState } from "react";
import { useEffect } from "react";
import NavBar from "./NavBar";
import Cards from "./Cards";
import Footer from "./Footer";
import RecentPost from "./RecentPost";
import video from "../video.mp4";


const Userpage = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://lsblogger-backend.logicspice.com/msg", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data, "userData");
        setData(data.data);
      });
  }, []);

  return (
    <>
      <div>
        <NavBar />
        <div className="section">
          <video className="img-fluid" playsInline autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
          <div className="centertext">
            <h1>Welcome to LS Blog Script</h1>
            <br />
            <h5>Build your own Blog page with Our LS Blog Script</h5>
            <br />
          </div>
        </div>
      </div>
      &nbsp;
      <p className="h2 text-center">Glimpses of our interesting Blogs!</p>
      <div className="container align-items-end">
        <hr />

        <div className="row">
          {data.map((i, id) => {
            return (
              <Cards 
                key={id}
                id={i._id}
                img={i.imageurl}
                title={i.title}
                body={i.description}
                link={i.link}
                date={i.date}
                comment={i.comment}
              />
            )
          })}


        </div>
      </div>
      <div>
        <RecentPost />
      </div>
      <Footer />
    </>
  );
};

export default Userpage;
