import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import RecentPost from "./RecentPost";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

const StaticPage = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState([]);
  const [userComment, setUserComment] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await Axios.get(
        `https://lsblogger-backend.logicspice.com/msg/${id}`
      );
      setPageData(response.data);
      console.log(response);
    } catch (error) {
      console.log("Couldn't get page data");
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserComment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAccept = (e) => {
    e.preventDefault();
    if (userComment.name === "" && userComment.email === "") {
      alert("⚠ Please fill all the details to post comment");
    } else {
      Axios.post(
        "https://lsblogger-backend.logicspice.com/staticpage",
        userComment
      );
      alert("✔ Comment submitted!");
      navigate("/");
    }
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <h1 className="mt-4">{pageData.title}</h1>
        <hr className="" />
        <div className="row static">
          <p className="text-muted">
            {pageData.createdAt}
          </p>
          <div className="col-md-6 col-lg-8 dynamicBody">
            <p className="description">
              {/* {HTMLReactParser(pageData.description) ? HTMLReactParser(pageData.description) : ""} */}
            </p>
          </div>
          <div className="col-md-6 col-lg-4">
            <img className="dynamicImg" src={pageData.imageurl} alt="" />
          </div>
        </div>
        <section>
          <div className="container description">
            <div className="row d-flex justify-content-left">
              <div className="col-md-6">
                <div className="">
                  <div className="">
                    <div className="d-flex flex-start align-items-center">
                      {/* <div>
                        {pageData.comment.map((comment, index) => { 
                         return (
                        <div>
                          <h2>Comments {pageData.comment.length()}</h2>
                          <hr style={{ width: "500px" }} />

                          {pageData.comment.map((i, index) => {
                            return (
                              <div key={index}>
                                <h5>
                                  <span className="fw-bold text-decoration-underline">
                                    {i.name} says:
                                  </span>{" "}
                                  {i.comment}
                                </h5>
                                <p className="fs-6 text-muted">
                                  Posted on: {i.date.substring(0, 10)}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        );
                        })}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <form>
                  <h2>Write your comments!</h2>
                  <hr />
                  <div
                    className="card-footer border-0"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="d-flex py-3 border-0 form-outline w-50">
                      <input
                        type="text"
                        id="form3Example1"
                        className="form-control me-2"
                        name="name"
                        value={userComment.name}
                        placeholder="Name"
                        onChange={handleChange}
                      />
                      <input
                        type="email"
                        id="form3Example1"
                        className="form-control"
                        name="email"
                        value={userComment.email}
                        placeholder="Email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="d-flex flex-start w-100">
                      <div className="form-outline w-100">
                        <textarea
                          className="form-control"
                          id="textAreaExample"
                          rows="4"
                          name="comment"
                          value={userComment.comment}
                          placeholder="Write your comment here.."
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="float-end mt-2 pt-1">
                      <button
                        type="button"
                        className="me-2 btn btn-dark btn-sm "
                        onClick={handleAccept}
                      >
                        Post comment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <div className="row static">
          <RecentPost />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StaticPage;
