import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Subscribe from "./Components/Subscribe";
import Userpage from "./Components/Userpage";
import Error from "./Components/Error";
import "./App.css";
import StaticPage from "./Components/StaticPage";
import Category from "./Components/Category";
import Footer from "./Components/Footer";
import Success from "./Components/Success";
import Search from "./Components/Search";
import SearchItem from "./Components/SearchItem";
import PostBlog from "./Components/PostBlog";
import ViewBlogs from "./Components/ViewBlogs";
import BlogSubscribers from "./Components/BlogSubscribers";
import BlogCategories from "./Components/BlogCategories";
import BlogComments from "./Components/BlogComments";
import Login from "./Components/Login";
import Edit from "./Components/Edit";
import CommentPage from "./Components/CommentPage";
import AdminUserPage from "./Components/AdminUserPage";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Userpage />} />
          <Route path="/subscribe" element={<Subscribe />} />
          {/* <Route path="/staticpage" element={<StaticPage  />} /> */}
          <Route path="/staticpage/:id" element={<StaticPage />} />

          <Route path="*" element={<Error />} />
          <Route path="/success" element={<Success />} />
          <Route path="/category" element={<Category />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/searchitem" element={<SearchItem />} />
          <Route path="/search" element={<Search />} />


          {/* Admin Routes */}
          <Route path="/dashboard" element={<AdminUserPage />} />
          <Route path="/postblog" element={<PostBlog />} />
          <Route path="/viewblogs" element={<ViewBlogs />} />
          <Route path="/edit/:id" element={<Edit />} />
          <Route path="/viewsubscribers" element={<BlogSubscribers />} />
          <Route path="/viewcategories" element={<BlogCategories />} />
          <Route path="/viewcomments" element={<BlogComments />} />
          <Route path="/commentpage/:id" element={<CommentPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
