import React from 'react';
import { Line } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import {LineController, LineElement, PointElement, LinearScale, Title,CategoryScale } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, ArcElement);

 
const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Growth as of every month',
      data: [5, 10, 8, 13, 19, 15, 20, 23, 29, 20, 17, 25, 33],
      backgroundColor: [
        'pink',
        '#17a2b8',
        '#ffc107',
        'rgb(87, 185, 96)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
 
const LineChart = () => (
  <>
    <div className='header'>
      
      <div className='links'>
         
      </div>
    </div>
    <Line data={data}  />
  </>
);


 
export default LineChart;