import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Pagination from "./Pagination";

const ViewBlogs = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   let username = sessionStorage.getItem("username");
  //   if (username === "" || username === null) {
  //     navigate("/login");
  //   }
  // });

  const [posts, setPosts] = useState([]);
  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [symbol, setSymbol] = useState("🔺");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPage, indexOfLastPost);

  //change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...posts].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setPosts(sorted);
      setOrder("DSC");
      setSymbol("🔻");
    }
    if (order === "DSC") {
      const sorted = [...posts].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setPosts(sorted);
      setOrder("ASC");
      setSymbol("🔺");
    }
  };

  const sortingbyNum = (col) => {
    if (order === "ASC") {
      const sorted = [...posts].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPosts(sorted);
      setOrder("DSC");
      setSymbol("🔻");
    }
    if (order === "DSC") {
      const sorted = [...posts].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPosts(sorted);
      setOrder("ASC");
      setSymbol("🔺");
    }
  };

  useEffect(() => {
    fetch("http://localhost:5000/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.data);
      });
  }, []);

  const filterData = (e) => {
    if (e.target.value !== "") {
      setValue(e.target.value);
      const filterTable = currentPosts.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
    }
  };

  const deletePost = async (id) => {
    try {
      if (window.confirm("Are you sure?")) {
        await Axios.delete(`http://localhost:5000/deletepost/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const savetoLocal = (id, title, imageurl, description, category) => {
    localStorage.setItem("postid", id);
    localStorage.setItem("title", title);
    localStorage.setItem("imageurl", imageurl);
    localStorage.setItem("description", description);
    localStorage.setItem("category", category);
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />
            <div className="col-lg-10 col-md-9 col-sm-12 mt-4">
              <h5 className="mb-3 text-dark text-center mt-5">
                Check Records of All Blogs
              </h5>
              <div className="form-outline d-flex float-right mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={value}
                  onChange={filterData}
                />
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("id")}>No {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th
                          style={{ paddingLeft: "110px" }}
                          onClick={() => sorting("title")}
                        >
                          Title {symbol}
                        </th>
                      </Tippy>
                      <th style={{ paddingLeft: "30px" }}>Posted On</th>
                      <th style={{ paddingLeft: "70px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {value.length > 0
                      ? tableFilter.map((output, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{output.title}</td>
                            <td>{output.date}</td>
                            <td>
                              <Link
                                to={`/edit/${output._id}`}
                                className="btn"
                                onClick={() =>
                                  savetoLocal(
                                    output._id,
                                    output.title,
                                    output.imageurl,
                                    output.description,
                                    output.category
                                  )
                                }
                              >
                                <i className="fa-solid fa-file-pen mr-2"></i>
                                Edit
                              </Link>
                              <a
                                className="btn"
                                href=" "
                                onClick={() => deletePost(output._id)}
                              >
                                <i
                                  className="fa-solid fa-trash mr-2"
                                  style={{ color: "#f00000" }}
                                ></i>
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))
                      : currentPosts.map((output, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{output.title}</td>
                            <td>{output.date}</td>
                            <td>
                              <Link
                                to={`/edit/${output._id}`}
                                className="btn"
                                onClick={() =>
                                  savetoLocal(
                                    output._id,
                                    output.title,
                                    output.imageurl,
                                    output.description,
                                    output.category
                                  )
                                }
                              >
                                <i className="fa-solid fa-file-pen mr-2"></i>
                                Edit
                              </Link>
                              <a
                                className="btn"
                                href=" "
                                onClick={() => deletePost(output._id)}
                              >
                                <i
                                  className="fa-solid fa-trash mr-2"
                                  style={{ color: "#f00000" }}
                                ></i>
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={posts.length}
                      paginate={paginate}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default ViewBlogs;
