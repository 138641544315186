import React from "react";
const Sidebar = () => {
  return (
    <div
      className="col-md-3 col-lg-2 sidebar-offcanvas pl-0"
      id="sidebar"
      role="navigation"
      style={{ backgroundColor: "#4f5d73", minHeight: "100vh", fontSize: "15px" }}
    >
      <ul className="nav flex-column sticky-top pl-0 pt-5 p-3 mt-3 sidebar">
        <li className="nav-item mb-2 mt-3">
          <a className="nav-link text-white text-left" href="/dashboard">
            <h4>Menu</h4>
          </a>
        </li>
        <li className="nav-item mb-2 ">
          <a className="nav-link text-white" href="/dashboard">
          <i className="fa-solid fa-gauge mr-4"></i>Dashboard
          <span className="badge bg-success ml-3">ALL</span>
          </a>
        </li>
        <li className="nav-item mb-2">
          <a
            className="nav-link text-white"
            href="/postblog"
            data-toggle="collapse"
            data-target="#submenu1"
          >
            <i className="far fa-file-word font-weight-bold mr-4"></i>Post a Blog
            <span className="badge bg-info ml-3">NEW</span>
          </a>
        </li>
        <li className="nav-item mb-2">
          <a className="nav-link text-white" href="/viewblogs">
            <i className="fas fa-file-export font-weight-bold"></i>
            <span className="ml-3">View Blogs</span>
          </a>
        </li>
        <li className="nav-item mb-2">
          <a className="nav-link text-white" href="/viewcategories">
            <i className="far fa-chart-bar font-weight-bold"></i>{" "}
            <span className="ml-3">Blog Categories</span>
          </a>
        </li>
        <li className="nav-item mb-2">
          <a className="nav-link text-white" href="/viewcomments">
          <i className="fa-solid fa-comments"></i>
            <span className="ml-3">Blog Comments</span>
          </a>
        </li>
        <li className="nav-item mb-2">
          <a className="nav-link text-white" href="/viewsubscribers">
          <i className="fa-solid fa-users"></i>
            <span className="ml-3">Blog Subscribers</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
