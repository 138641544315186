import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import {
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  ArcElement
);

const data = {
  labels: ["Technology", "Sports", "Tourism", "Art", "Science", "Others"],
  datasets: [
    {
      label: "# of Votes",
      data: [22, 6, 12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "pink",
        "#17a2b8",
        "#ffc107",
        "rgb(87, 185, 96)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const DoughnutChart = () => (
  <>
    <div className="header">
      <div className="links"></div>
    </div>
    <Doughnut data={data} />
  </>
);

export default DoughnutChart;
