import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import HTMLReactParser from "html-react-parser";


const CommentPage = () => {
  const [post, setPost] = useState([]);
  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [symbol, setSymbol] = useState("🔺");
  // const [commentData, setCommentData] = useState({
  //   name: "",
  //   email: "",
  //   comment: ""
  // });

  // const [currentPage, setcurrentPage] = useState(1);
  // const [postsPerPage] = useState(4);

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPage = indexOfLastPost - postsPerPage;
  // const currentPosts = post.slice(indexOfFirstPage, indexOfLastPost);

  //change page
  // const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const { id } = useParams();

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...post].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setPost(sorted);
      setOrder("DSC");
      setSymbol("🔻")
    }
    if (order === "DSC") {
      const sorted = [...post].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setPost(sorted);
      setOrder("ASC");
      setSymbol("🔺")
    }
  };

  const sortingbyNum = (col) => {
    if (order === "ASC") {
      const sorted = [...post].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setPost(sorted);
      setOrder("DSC");
      setSymbol("🔻")
    }
    if (order === "DSC") {
      const sorted = [...post].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setPost(sorted);
      setOrder("ASC");
      setSymbol("🔺")
    }
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   let username = sessionStorage.getItem("username");
  //   if (username === "" || username === null) {
  //     navigate("/login");
  //   }
  // });

  useEffect(() => {
    getPost(id);
  }, );

  const filterData = (e) => {
    if (e.target.value !== "") {
      setValue(e.target.value);
      const filterTable = post.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
    }
  };

  const getPost = async (id) => {
    try {
      const response = await Axios.get(`http://localhost:5000/post/${id}`);
      setPost(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  const deleteComment = async (id, name, email, comment, postid, title) => {
    const commentDetails = {name, email, comment, title};
    console.log(postid);
    console.log(id);
    try {
      if (window.confirm("Are you sure?")) {
        await Axios.post(`http://localhost:5000/posts/${postid}/comments/${id}`, commentDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />
            <div className="col-lg-10 col-md-9 col-sm-12 mt-4" style={{ paddingTop: "60px" }}>
              <div className="table-responsive">
                {post.map((output) => (
                  <>
                    <h3 style={{fontSize: "28px", marginBottom: "30px"}}>{HTMLReactParser(output.title)}</h3>
                    <h6 className="text-justify" style={{lineHeight: "2rem", fontSize: "20px"}}>{HTMLReactParser(output.description)}</h6>
                    <hr />
                    <h5 className="mb-3 text-dark text-center mt-2">
                      Check comments on this blog here
                    </h5>
                  </>
                ))}
                <div className="form-outline d-flex float-right mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={value}
                    onChange={filterData}
                  />
                </div>
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("id")}>No {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sorting("name")}>Name {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("email")}>Email {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sorting("comment")}>Comment {symbol}</th>
                      </Tippy>
                      <th style={{ paddingLeft: "30px" }}>Actions</th>
                    </tr>
                  </thead>

                  <tbody className="tbody">
                    {value.length > 0 ? tableFilter.map((output) => (
                      
                        output.comment?.map((i, index) => (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{i.name}</td>
                              <td>{i.email}</td>
                              <td>{i.comment}</td>
                              <td>
                                <a
                                  className="btn"
                                  href=" "
                                  onClick={() => deleteComment(i._id, i.name, i.email, i.comment, output._id, output.title)}
                                >
                                  <i
                                    className="fa-solid fa-trash mr-2"
                                    style={{ color: "#f00000" }}
                                  ></i>
                                  Delete
                                </a>
                              </td>
                            </tr>
                          </>
                        ))
                    ))
                  :
                  post.map((output) => (
                      
                    output.comment?.map((i, index) => (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          <td>{i.email}</td>
                          <td>{i.comment}</td>
                          <td>
                            <a
                              className="btn"
                              href=" "
                              onClick={() => deleteComment(i._id, i.name, i.email, i.comment, output._id, output.title)}
                            >
                              <i
                                className="fa-solid fa-trash mr-2"
                                style={{ color: "#f00000" }}
                              ></i>
                              Delete
                            </a>
                          </td>
                        </tr>
                      </>
                    ))
                ))}
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default CommentPage;




