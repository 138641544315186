import React, { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Search from "./Search";
import { useEffect } from "react";
import Pagination from "./Pagination";

const SearchItem = () => {
  const [query, setQuery] = useState("");
  console.log(query);

  const filterSearch = (data) => {
    return data.filter((item) => item.title.toLowerCase().includes(query));
  };

  const [searchdata, setSearchdata] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage] = useState(11);
  useEffect(() => {
    fetch("https://lsblogger-backend.logicspice.com/msg", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setSearchdata(data.data);
      });
  }, []);
  console.log(searchdata);

  //get current posts

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  const currentPosts = searchdata.slice(indexOfFirstPage, indexOfLastPost);

  //change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  return (
    <>
      <NavBar />
      <div className="container w-50">
        <p className=" text-center h3 pt-3">Search blogs here!</p>

        <form className="d-flex" role="search">
          <input
            className="form-control me-2"
            type="search"
            placeholder="Type here.."
            aria-label="Search"
            onChange={(e) => setQuery(e.target.value)}
          />
        </form>
        <Search props={filterSearch(currentPosts)} />
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={searchdata.length}
          paginate={paginate}
        />
      </div>
      <Footer />
    </>
  );
};

export default SearchItem;
