import { useEffect, useState } from "react";
import Footer from "./Footer";
import BarChart from "./Charts/BarChart";
import LineChart from "./Charts/LineChart";
import DoughnutChart from "./Charts/DoughnutChart";
import PolarAreaChart from "./Charts/PolarAreaChart";
import { useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

const Dashboard = () => {
  const navigate = useNavigate();
  const [record, setRecord] = useState([]);
  const [subscribersLength, setSubscribersLength] = useState([]);

  useEffect(() => {
    fetch("http://localhost:5000/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setRecord(data.data);
      });
  }, []);

  useEffect(() => {
    fetch("http://localhost:5000/getsubscribers", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setSubscribersLength(data.data);
      });
  }, []);

  return (
    <>
      <div className="col main pt-5 mt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Data
            </li>
          </ol>
        </nav>
        <hr />
        <div className="h4 text-center">
          Details for Subscribers, Posts and Categories
        </div>
        <div className="row mb-3">
          <div className="col-xl-4 col-sm-6 py-2">
            <div
              className="card btn text-white text-center h-100"
              onClick={() => navigate("/viewsubscribers")}
              style={{ backgroundColor: "#922724" }}
            >
              <div className="card-body">
                <div className="rotate">
                  <i className="fa fa-user fa-4x"></i>
                </div>
                <h6 className="text-uppercase mt-2">Subscribers</h6>
                {subscribersLength
                  .slice(subscribersLength.length - 1)
                  .map((output, index) => (
                    <h1 className="display-4" style={{ marginBottom: "10px" }}>
                      {subscribersLength.length}
                    </h1>
                  ))}
                <DoughnutChart />
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 py-2">
            <div
              className="card btn text-white text-center h-100"
              onClick={() => navigate("/viewblogs")}
              style={{ backgroundColor: "#80461b" }}
            >
              <div className="card-body">
                <div className="rotate">
                  <i className="fa fa-list fa-4x"></i>
                </div>
                <h6 className="text-uppercase">Posts</h6>
                {record.slice(record.length - 1).map((output, index) => (
                  <h1 className="display-4" style={{ paddingBottom: "10px" }}>
                    {record.length}
                  </h1>
                ))}
                <PolarAreaChart />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 py-2">
            <div
              className="card btn text-white text-center h-100"
              onClick={() => navigate("/viewcategories")}
              style={{ backgroundColor: " #FA5F55 " }}
            >
              <div className="card-body">
                <div className="rotate">
                  <i
                    className="fa-solid fa-layer-group fa-2xl"
                    style={{
                      width: "90px",
                      height: "60px",
                      paddingTop: "30px",
                    }}
                  ></i>
                </div>
                <h6 className="text-uppercase">Categories</h6>
                {record.slice(record.length - 1).map((output, index) => (
                  <h1 className="display-4">{record.length}</h1>
                ))}
                <DoughnutChart />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-sm-offset-5">
            <h4 className="title mt-3 text-center text-secondary">
              Data in Chart
            </h4>
            <div
              className="mt-3"
              style={{ height: "700px", width: "1000px", margin: "auto" }}
            >
              <LineChart />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h5 className="mb-3 text-secondary text-center">
              Records of recent 5 Blog Posts
            </h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Record Date</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {record.slice(record.length - 5).map((output, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{HTMLReactParser(output.title)}</td>
                      <td>{HTMLReactParser(output.description.substring(0, 100))}...</td>
                      <td>{output.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <div className="text-center mb-4">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
