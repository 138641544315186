import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="sticky-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <NavLink to="/" className="navbar-brand ms-3" href="/">
            <img src="/Images/lslogo.png" alt="Logicspice logo" width="100" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" aria-current="page">
                  Home
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/category"
                  className="nav-link"
                  aria-current="page"
                >
                  Categories
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/subscribe" className="nav-link" href="/">
                  Subscribe
                </NavLink>
              </li>
            </ul>
            <div className="nav-item text-white me-5">
              <NavLink
                to="/searchitem"
                className="nav-link"
                aria-current="page"
              >
                <i className="fa-solid fa-magnifying-glass me-2"></i>Search
                blogs
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
