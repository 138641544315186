import React from "react";
import { Link } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

const Search = ({ props }) => {


  return (
    <>
      {props.map((d) => (
        <div id="cardsection" className="container">
          <div className="card my-3">
            <div className="bg-image">
              <Link to={`/staticpage/${d._id}`}>
                <img src={d.imageurl} className="card-img-top" alt="" />
              </Link>
            </div>

            <div className="card-body">
              <h5 className="card-title">{HTMLReactParser(d.title)}</h5>
              <p className="card-text">
                {HTMLReactParser(d.description.substring(0, 200) + "...")}
              </p>

              <Link
                to={`/staticpage/${d._id}`}
                className="btn btn-outline-dark d-flex justify-content-center"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Search;
