import React from 'react'

const Pagination = ({postsPerPage, totalPosts, paginate}) => {
  const pageNumber = [];

  for(let i = 1; i<= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumber.push(i);
  }
  
    return (
    <nav>
        <ul className='pagination mt-2'>
            {pageNumber.map(number => (
                <li key={number} className='page-item'>
                    <button onClick={() => paginate(number)} className='page-link text-black'>
                        {number}
                    </button>
                </li>
            ))}
        </ul>
      
    </nav>
  )
}

export default Pagination
