import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import HTMLReactParser from "html-react-parser";

const Catogory = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [filterCategoryData, setFilterCategoryData] = useState([]);

  useEffect(() => {
    fetch("https://lsblogger-backend.logicspice.com/msg", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data);
        setFilterCategoryData(data.data);
      });
  }, []);

  const filterResult = (catItem) => {
    if (catItem === "All") {
      setFilterCategoryData(categoryData);
    } else {
      let result = categoryData.filter((curData) => {
        return curData.category === catItem;
      });
      setFilterCategoryData(result);
    }
  };

  return (
    <>
      <NavBar />
      <div className="container-fluid mx-2">
        <div className="row mt-5 mx-2">
          <div className="col-md-4 col-lg-3">
            <div className="card p-3">
              <h3 className="text-center">Categories</h3>
              <hr />
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("Art")}
              >
                Art
              </button>
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("Science")}
              >
                Science
              </button>
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("Technology")}
              >
                Technology
              </button>
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("Sports")}
              >
                Sports
              </button>
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("Tourism")}
              >
                Tourism
              </button>
              <button
                className="btn btn-dark w-100 mb-4"
                onClick={() => filterResult("All")}
              >
                All
              </button>
            </div>
          </div>

          <div className="col-md-8 col-lg-9">
            <div className="row">
              {filterCategoryData.map((i, id) => {
                return (
                  <>
                    <div className="col-md-6 col-lg-4 mb-4" key={id}>
                      <div className="card">
                        <div className="bg-image">
                          <Link to={`/staticpage/${i._id}`}>
                            <img
                              src={i.imageurl}
                              className="card-img-top"
                              alt="This is a pic"
                            />
                          </Link>
                        </div>

                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{
                              minHeight: "35px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {HTMLReactParser(i.title)}
                          </h5>
                          <hr />
                          <p
                            className="card-text"
                            style={{
                              minHeight: "76px",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            {HTMLReactParser(
                              i.description.substring(0, 100) + "..."
                            )}
                          </p>

                          <Link
                            to={`/staticpage/${i._id}`}
                            className="btn btn-outline-dark d-flex justify-content-center"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Catogory;
