import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useState, useEffect,useRef } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import avatar from "../assets/image.png";
import JoditEditor from "jodit-react";

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editor = useRef(null);

  // useEffect(() => {
  //   let username = sessionStorage.getItem("username");
  //   if (username === "" || username === null) {
  //     navigate("/login");
  //   }
  // });

  const [edit, setEdit] = useState({
    id: "",
    title: "",
    imageurl: "",
    description: "",
    category: ""
  })

  useEffect(() => {
    setEdit({...edit, id: localStorage.getItem("postid"), title: localStorage.getItem("title"), imageurl: localStorage.getItem("imageurl"), description: localStorage.getItem("description"), category: localStorage.getItem("category")});
  }, []);

  const handleSubmit = async (id, edit) => {
    console.log(edit);
    try {
      if (window.confirm("Do you want to save changes?")) {
        await Axios.put(`http://localhost:5000/updateblog/${id}`, edit);
      }
      navigate("/viewblogs");
    } catch (error) {
      console.log("Error while updating!", error);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setEdit({...edit, imageurl : base64});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEdit((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />

            <section className="mt-5 container sub">
              <h4 className="mb-5 mt-5 text-center">
                <strong>Edit Post</strong>
              </h4>
              <hr />  
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row mb-4">
                      <div className="col">
                        <label htmlFor="">Title:</label>
                        <div className="form-outline">
                          <input
                            type="text"
                            id="form3Example2"
                            className="form-control"
                            name="title"
                            placeholder="Blog Title"
                            value={edit.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-outline mb-4">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        Upload Image:
                      </label>
                      <p style={{color: "red"}}>*Only JPEG, JPG and PNG files less than 1 MB supported</p>
                      <input
                        className="ml-2"
                        type="file"
                        lable="Image"
                        name="imageurl"
                        id="file-upload"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <img
                        src={edit.imageurl || avatar}
                        alt=""
                        style={{ width: "100px" }}
                      />
                      {edit.imageurl ? (
                        <button
                          className="btn-sm btn-outline-dark ml-3"
                          onClick={() => setEdit({...edit, imageurl: ""})}
                        >
                          Delete
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-outline mb-4">
                      <label htmlFor="">Description:</label>
                      <JoditEditor
                        ref={editor}
                        name="description"
                        value={edit.description}
                        onChange={description => handleChange({ target: { value: description, name: 'description' } })}
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label for="cars">Choose a category:</label>

                      <select
                        name="category"
                        id="form3Example4"
                        className="form-control"
                        value={edit.category}
                        onChange={handleChange}
                      >
                        <option value="Select" inactive>
                          Select
                        </option>
                        <option value="Technology">Technology</option>
                        <option value="Tourism">Tourism</option>
                        <option value="Art">Art</option>
                        <option value="Science">Science</option>
                        <option value="Sports">Sports</option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-outline-dark btn-block mb-4 mt-2 w-25"
                      onClick={() => handleSubmit(id, edit)}
                    >
                      Update Blog
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default Edit;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
