import React from "react";

const Footer = () => {
  return (
    <div>
      <>
        <footer className="bg-dark text-center text-white">
          <div className="container p-4 pb-0">
            <section className="mb-4">
              <h6>Reach us: </h6>
              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-facebook-f"></i>
              </a>

              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-twitter"></i>
              </a>

              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-google"></i>
              </a>

              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-instagram"></i>
              </a>

              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>

              <a
                className="btn btn-outline-light btn-floating m-1"
                href="/"
                role="button"
              >
                <i className="fab fa-github"></i>
              </a>
            </section>
          </div>
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            © 2023 Copyright:
            <a
              className="text-white text-decoration-none"
              href="https://www.logicspice.com/"
            >
              {" "}
              LogicSpice Pvt. Ltd.
            </a>
          </div>
        </footer>
      </>
    </div>
  );
};

export default Footer;
