import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import { useState } from "react";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const Subscribe = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
  });

  const [captcha, setCaptcha] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((preve) => {
      return {
        ...preve,
        [name]: value,
      };
    });
    console.log(user);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    console.log(user);
    if (user.fname === "" || user.lname === "" || user.email === "") {
      alert("⚠ Please fill the details properly");
    } else {
      if (!captcha) {
        alert("⚠ Please check the captcha");
      }
      if (captcha) {
        Axios.post("https://lsblogger-backend.logicspice.com/subscribe", user);
        navigate("/success");
      }
      // alert("Successfully subscribed!");
    }
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div>
        <section className="mb-5 mt-5 container sub">
          <h4 className="mb-5 text-center">
            <strong>Subscribe to our blog!</strong>
          </h4>

          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <form>
                {/* 2 column grid layout with text inputs for the first and last names */}
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <input
                        type="text"
                        id="form3Example1"
                        className="form-control"
                        name="fname"
                        onChange={handleChange}
                        value={user.fname}
                        placeholder="First name"
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-outline">
                      <input
                        type="text"
                        id="form3Example2"
                        className="form-control"
                        name="lname"
                        onChange={handleChange}
                        value={user.lname}
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                </div>
                {/* <Email input */}
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form3Example3"
                    className="form-control"
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                    placeholder="Email"
                  />
                </div>
                {/* Password input */}
                {/* <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="form3Example4"
                    className="form-control"
                    name="password"
                    onChange={handleChange}
                    value={user.password}
                    placeholder="Password"
                  />
                </div> */}

                {/* recaptcha */}
                <ReCAPTCHA
                  sitekey="6LeRpjklAAAAAHyjfMmz8anE4GMxRuZf_vuEVYgZ"
                  onChange={() => setCaptcha(true)}
                  required
                />
                {/* Submit button */}
                <button
                  to="/success"
                  type="submit"
                  className="btn btn-outline-dark btn-block mb-4 mt-4"
                  onClick={handleAccept}
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Subscribe;
