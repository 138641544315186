import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Pagination from "./Pagination";

const BlogComments = () => {
  const [allcomment, setAllComment] = useState([]);
  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [symbol, setSymbol] = useState("🔺");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  const currentPosts = allcomment.slice(indexOfFirstPage, indexOfLastPost);

  //change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = [...allcomment].sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setAllComment(sorted);
  //     setOrder("DSC");
  //     setSymbol("🔻")
  //   }
  //   if (order === "DSC") {
  //     const sorted = [...allcomment].sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setAllComment(sorted);
  //     setOrder("ASC");
  //     setSymbol("🔺")
  //   }
  // };

  const sortingbyNum = (col) => {
    if (order === "ASC") {
      const sorted = [...allcomment].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setAllComment(sorted);
      setOrder("DSC");
      setSymbol("🔻")
    }
    if (order === "DSC") {
      const sorted = [...allcomment].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setAllComment(sorted);
      setOrder("ASC");
      setSymbol("🔺")
    }
  };

  const navigate = useNavigate();

  // useEffect(() => {
  //   let username = sessionStorage.getItem("username");
  //   if (username === "" || username === null) {
  //     navigate("/login");
  //   }
  // });


  const filterData = (e) => {
    if (e.target.value !== "") {
      setValue(e.target.value);
      const filterTable = currentPosts.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    fetch("http://localhost:5000/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllComment(data.data);
      });
  }, []);

  const deletePost = async (id) => {
    try {
      if(window.confirm("Are you sure?")){
        await Axios.delete(`http://localhost:5000/deletepost/${id}`);
      }
    } catch (error) {
      console.log(error); 
    }
  };


  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />
            <div className="col-lg-10 col-md-9 col-sm-12 mt-4">
              <h5 className="mb-3 text-dark text-center mt-5">
                Check Records of Blog Comments
              </h5>
              <div className="form-outline d-flex float-right mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={value}
                  onChange={filterData}
                />
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                    <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("id")}>No {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("name")}>Name {symbol}</th>
                      </Tippy>
                      <th style={{ paddingLeft: "90px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {value.length > 0 ? tableFilter.map((output,index) => (
                      <tr>
                        <td>{index+1}</td>
                        <td>{output.title}</td>
                        {/* {output.comment?.map((i) => {
                          return <td><li>{i.comment.length>1?i.comment:" "}</li></td>;
                        })} */}
                        <td>
                          <Link to={`/commentpage/${output._id}`} className="btn">
                          <i class="fa-solid fa-eye mr-2"></i>Comments
                          </Link>
                          <a className="btn" href=" " onClick={() => deletePost(output._id)}>
                            <i
                              className="fa-solid fa-trash mr-2"
                              style={{ color: "#f00000" }}
                            ></i>Delete
                          </a>
                        </td>
                      </tr>
                    ))
                  :
                  currentPosts.map((output, index) => (
                    <tr>
                      <td>{index+1}</td>
                      <td>{output.title}</td>
                      {/* {output.comment?.map((i) => {
                        return <td><li>{i.comment.length>1?i.comment:" "}</li></td>;
                      })} */}
                      <td>
                        <Link to={`/commentpage/${output._id}`} className="btn">
                        <i class="fa-solid fa-eye mr-2"></i>Comments
                        </Link>
                        <a className="btn" href=" " onClick={() => deletePost(output._id)}>
                          <i
                            className="fa-solid fa-trash mr-2"
                            style={{ color: "#f00000" }}
                          ></i>Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={allcomment.length}
                      paginate={paginate}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default BlogComments;
