import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RecentPost = () => {
  const [recentData, setRecentData] = useState([]);

  useEffect(() => {
    fetch("https://lsblogger-backend.logicspice.com/msg", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setRecentData(data.data);
      });
  }, []);

  recentData.reverse().splice(5);

  return (
    <>
      <div className="container">
        <div className="row d-flex justify-content-start">
          <div className="col-lg-3">
            <div className="card my-3 ">
              <div className="card-body">
                <h5 className="card-title text-center">Our Recent Posts</h5>
                <p className="card-text d-flex flex-column mb-3">
                  {recentData.map((i, id) => (
                    <Link
                      to={`/staticpage/${i._id}`}
                      key={id}
                      className="py-2 fw-semibold text-dark text-decoration-none textrecent"
                    >
                      <i className="fa-solid fa-arrow-right-long"></i> {i.title}
                    </Link>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentPost;
