// import React, { useState } from "react";
import { Link } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

const Cards = (props) => {

  return (
    <>
      <div id="cardsection" className="col-md-4 col-lg-3">
        <div className="card my-3 ">
          <div className="bg-image">
            <img
              src={props.img}
              className="card-img-top"
              alt="This is a pic"
        
            />
          </div>

          <div className="card-body">
            <h5
              className="card-title"
              style={{ minHeight: "35px", margin: "0px", padding: "0px" }}
            >
              {HTMLReactParser(props.title)}
            </h5>
            <hr />
            <p
              className="card-text"
              style={{ minHeight: "76px", margin: "0px", padding: "0px" }}
            >
              {HTMLReactParser(props.body.substring(0, 80) + "...")}
            </p>
            <Link
              to={`/staticpage/${props.id}`}
             
              className="btn btn-outline-dark d-flex justify-content-center"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
