import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import Pagination from "./Pagination";

const BlogCategories = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   let username = sessionStorage.getItem("username");
  //   if (username === "" || username === null) {
  //     navigate("/login");
  //   }
  // });

  const [categories, setCategories] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [tableFilter, setTableFilter] = useState([]);
  const [value, setValue] = useState("");
  const [symbol, setSymbol] = useState("🔺");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  const currentPosts = categories.slice(indexOfFirstPage, indexOfLastPost);

  //change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);


  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...categories].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCategories(sorted);
      setOrder("DSC");
      setSymbol("🔻");
    }
    if (order === "DSC") {
      const sorted = [...categories].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCategories(sorted);
      setOrder("ASC");
      setSymbol("🔺");
    }
  };

  const sortingbyNum = (col) => {
    if (order === "ASC") {
      const sorted = [...categories].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setCategories(sorted);
      setOrder("DSC");
      setSymbol("🔻");
    }
    if (order === "DSC") {
      const sorted = [...categories].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setCategories(sorted);
      setOrder("ASC");
      setSymbol("🔺");
    }
  };

  useEffect(() => {
    fetch("http://localhost:5000/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data.data);
      });
  }, []);
  console.log(categories);

  const filterData = (e) => {
    if (e.target.value !== "") {
      setValue(e.target.value);
      const filterTable = currentPosts.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (id) => {
    handleShow();
    setPid(id);
  };

  const [Pid, setPid] = useState();
  const [updatedCategory, setUpdatedCategory] = useState({
    category: "",
  });
  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCategory((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  console.log(updatedCategory);

  const saveUpdatedPost = async (Pid, updatedCategory) => {
    try {
      await Axios.post(
        `http://localhost:5000/updatecategory/${Pid}`,
        updatedCategory,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      handleClose();
      window.location.reload();
    } catch (error) {
      console.log("Error while updating the category!");
    }
  };

  return (
    <>
      <div>
        <Navbar />
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
            <Sidebar />

            <div className="col-lg-10 col-md-9 col-sm-12 mt-4">
              <h5 className="pb-3 text-dark text-center mt-5">
                Check Records of Blog Categories
              </h5>
              <div className="form-outline d-flex float-right mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={value}
                  onChange={filterData}
                />
              </div>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("id")}>No {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sorting("title")}>Title {symbol}</th>
                      </Tippy>
                      <Tippy content="Click to sort">
                        <th onClick={() => sortingbyNum("category")}>
                          Categories {symbol}
                        </th>
                      </Tippy>

                      <th>Update Category</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {value.length > 0
                      ? tableFilter.map((output, index) => (
                          <>
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Update Category</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {/* <Form.Control
                                  placeholder="Add new Category"
                                  name="category"
                                  value={updatedCategory.category} onChange={handleCategoryChange}
                                /> */}

                                <select
                                  name="category"
                                  className="form-control"
                                  value={updatedCategory.category}
                                  onChange={handleCategoryChange}
                                >
                                  <option value="Select" inactive>
                                    Select
                                  </option>
                                  <option value="Technology">Technology</option>
                                  <option value="Tourism">Tourism</option>
                                  <option value="Art">Art</option>
                                  <option value="Science">Science</option>
                                  <option value="Sports">Sports</option>
                                </select>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="outline-danger"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="outline-dark"
                                  onClick={() =>
                                    saveUpdatedPost(Pid, updatedCategory)
                                  }
                                >
                                  Save Changes
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <tr>
                              <td>{index+1}</td>
                              <td>{output.title}</td>
                              <td>{output.category}</td>
                              <td>
                                <Link
                                  className="text-decoration-none text-dark"
                                  onClick={() => handleClick(output._id)}
                                >
                                  <i class="fa-solid fa-folder-plus mr-2"></i>
                                  Update
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/edit/${output._id}`}
                                  className="btn"
                                >
                                  <i className="fa-solid fa-file-pen mr-2"></i>
                                  Edit
                                </Link>
                              </td>
                            </tr>
                          </>
                        ))
                      : currentPosts.map((output,index) => (
                          <>
                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>New Category</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {/* <Form.Control
                                  placeholder="Add new Category"
                                  name="category"
                                  value={updatedCategory.category}
                                  onChange={handleCategoryChange}
                                /> */}

                                <select
                                  name="category"
                                  className="form-control"
                                  value={updatedCategory.category}
                                  onChange={handleCategoryChange}
                                >
                                  <option value="Select" inactive>
                                    Select
                                  </option>
                                  <option value="Technology">Technology</option>
                                  <option value="Tourism">Tourism</option>
                                  <option value="Art">Art</option>
                                  <option value="Science">Science</option>
                                  <option value="Sports">Sports</option>
                                </select>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="outline-danger"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="outline-dark"
                                  onClick={() =>
                                    saveUpdatedPost(Pid, updatedCategory)
                                  }
                                >
                                  Save Changes
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <tr>
                              <td>{index+1}</td>
                              <td>{output.title}</td>
                              <td>{output.category}</td>
                              <td>
                                <Link
                                  className="text-decoration-none text-dark"
                                  onClick={() => handleClick(output._id)}
                                >
                                  <i class="fa-solid fa-folder-plus mr-2"></i>
                                  Update
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/edit/${output._id}`}
                                  className="btn"
                                >
                                  <i className="fa-solid fa-file-pen mr-2"></i>
                                  Edit
                                  {/* <i
                            className="fa-solid fa-trash"
                            style={{ color: "#f00000" }}
                          ></i> */}
                                </Link>
                              </td>
                            </tr>
                          </>
                        ))}
                  </tbody>
                </table>
                <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={categories.length}
                      paginate={paginate}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-4">
        <Footer />
      </div>
    </>
  );
};

export default BlogCategories;
