import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [username, usernameupdate] = useState("");
  const [password, passwordupdate] = useState("");
  // const [credentials, setCredentials] = useState();


  const usenavigate = useNavigate();



  useEffect(() => {
    sessionStorage.clear();
    // fetch("http://localhost:5000/getlogindata", {
    //   method: "GET"
    // })
    // .then((res) => 
    //   res.json()
    // )
    // .then((data) => {
    //   setCredentials(data.data)
    // })

    
  }, []);

  const ProceedLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      fetch("http://localhost:8000/user/" + username)
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          console.log(resp);
          if (Object.keys(resp).length === 0) {
            toast.error("Please Enter valid username");
          } else {
            if (resp.password === password) {
              sessionStorage.setItem("username", username);
              sessionStorage.setItem("userrole", resp.role);
              usenavigate("/");
              toast.success("Success");
            } else {
              toast.error("Please Enter valid credentials");
            }
          }
        })
        .catch((err) => {
          toast.error("Login Failed due to :" + err.message);
        });
    }
  };

  // const ProceedLoginusingAPI = (e) => {
  //     e.preventDefault();
  //     if (validate()) {
  //         ///implentation
  //         // console.log('proceed');
  //         let inputobj={"username": username,
  //         "password": password};
  //         fetch("https://localhost:44308/User/Authenticate",{
  //             method:'POST',
  //             headers:{'content-type':'application/json'},
  //             body:JSON.stringify(inputobj)
  //         }).then((res) => {
  //             return res.json();
  //         }).then((resp) => {
  //             console.log(resp)
  //             if (Object.keys(resp).length === 0) {
  //                 toast.error('Login failed, invalid credentials');
  //             }else{
  //                  toast.success('Success');
  //                  sessionStorage.setItem('username',username);
  //                  sessionStorage.setItem('jwttoken',resp.jwtToken);
  //                usenavigate('/')
  //             }
  //             // if (Object.keys(resp).length === 0) {
  //             //     toast.error('Please Enter valid username');
  //             // } else {
  //             //     if (resp.password === password) {
  //             //         toast.success('Success');
  //             //         sessionStorage.setItem('username',username);
  //             //         usenavigate('/')
  //             //     }else{
  //             //         toast.error('Please Enter valid credentials');
  //             //     }
  //             // }
  //         }).catch((err) => {
  //             toast.error('Login Failed due to :' + err.message);
  //         });
  //     }
  // }
  const validate = () => {
    let result = true;
    if (username === "" || username === null) {
      result = false;
      toast.warning("Please Enter Username");
    }
    if (password === "" || password === null) {
      result = false;
      toast.warning("Please Enter Password");
    }
    return result;
  };
  return (
    <>
      <div className="wrapper">
        <div className="logo">
          <img src="/Images/LSlogo.png" alt="" style={{ width: "80px" }} />
        </div>
        <div className="text-center mt-4 name">Admin</div>
        <form className="p-3 mt-3" onSubmit={ProceedLogin}>
        <ToastContainer autoClose={2000}/>
          <div className="form-field d-flex align-items-center">
            <span className="far fa-user"></span>
            <input
              type="text"
              value={username}
              onChange={(e) => usernameupdate(e.target.value)}
              placeholder="Username"
            />
          </div>
          <div className="form-field d-flex align-items-center">
            <span className="fas fa-key"></span>
            <input
              type="password"
              value={password}
              onChange={(e) => passwordupdate(e.target.value)}
              placeholder="Password"
            />
          </div>
          <button className="btn mt-3">Login</button>
        </form>
        <div className="text-center fs-6">
          <a href=" ">Forget password?</a>
        </div>
      </div>
    </>
    
  );
};

export default Login;
