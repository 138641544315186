import React from 'react'
import { useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import { useNavigate } from 'react-router-dom';

const AdminUserPage = () => {

  const navigate = useNavigate();

  // useEffect(() => {
  //   let username =sessionStorage.getItem('username');
  //   if(username==='' || username===null){
  //     navigate('/login');
  //   }
  // })
  
  return (
    <>
    <div>
    <Navbar />
        
        <div className="container-fluid" id="main">
          <div className="row row-offcanvas row-offcanvas-left">
          
            <Sidebar />
            <Dashboard />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminUserPage
